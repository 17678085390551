<template>
  <FocusTrap>
    <div id = "mycard">

    <div class="page-header page-header-light">
      <div class="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
        <div class="d-flex">
          <div class="breadcrumb">
            <a href="/" class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Home</a>
            <a href="#" class="breadcrumb-item">Knitting</a>
            <a href="#" class="breadcrumb-item">Rolling</a>
            <span class="breadcrumb-item active">New Roll</span>
          </div>

          <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
        </div>

        <div class="header-elements d-none">

          <div class="breadcrumb justify-content-center">
            <!--            <a href="#" class="breadcrumb-elements-item">-->
            <!--              <i class="icon-youtube mr-2" style="color: red;"></i>-->
            <!--              Support-->
            <!--            </a>-->
            <div class="list-icons">
              <i class="icon-youtube mr-2" style="color: red;"></i>
              <a class="list-icons-item" style="color: forestgreen;" data-action="reload" @click="loadData" ></a>
              <a class="list-icons-item" style="color: orangered;" data-action="remove" @click="closeThis"></a>
            </div>
          </div>

        </div>

      </div>
    </div>

    <FocusTrap>

      <div class="card" id="mycard" style="padding: 5px;">

        <div class="card-body">

          <div class="row">

            <div class="col-md-2">
              <div class="form-group form-group-material ">
                <label class="control-label text-semibold">Shift</label>
                <select id="cmbshift" class="form-control" autofocus="" v-if="voucher" v-model="voucher.shift_id">
                  <option value="2">Day</option>
                  <option value="3">Night</option>
                </select>
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-group form-group-material">
                <label class="control-label text-semibold">Process</label>
                <select class="form-control" required=""  v-model="voucher.process_id" disabled >
                  <option value="350" selected>Knitting</option>
                  <option value="305">Weaving</option>
                </select>
              </div>
            </div>

            <div class="col-md-2">
              <!--              <div class="form-group form-group-material">-->
              <!--                <label class="control-label text-semibold">Operator</label>-->
              <!--                <select class="form-control" required="" v-if="voucher" v-model="voucher.emp.id" >-->
              <!--                  <option v-for="emp in emps" v-bind:value="emp.id">-->
              <!--                    {{ emp.name }}-->
              <!--                  </option>-->
              <!--                </select>-->
              <!--              </div>-->
            </div>


            <div class="col-md-4">

            </div>


            <div class="col-md-2">
              <div class="form-group form-group-material">
                <label class="control-label text-semibold">Date</label>
                <input type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">

              <!-- Static mode -->
              <div class="card">
                <div class="card-header header-elements-inline">
                  <h5 class="card-title">Fabric Information</h5>
                  <div class="header-elements">

                  </div>
                </div>

                <div class="card-body">


                  <div class="form-group row">

                    <label class="col-form-label col-md-1">Quality</label>
                    <div class="col-md-3">
                      <select class="form-control" v-if="qualities" v-model="voucher.quality_id">
                        <option v-for="itm in qualities" v-bind:value="itm.id">
                          {{ itm.name }}
                        </option>
                      </select>
                    </div>

                    <label class="col-form-label col-md-1">Color</label>
                    <div class="col-md-3">
                      <select  class="form-control" v-if="colors" v-model="voucher.color_id">
                        <option v-for="color in colors" v-bind:value="color.id">
                          {{ color.name }}
                        </option>
                      </select>
                    </div>


                    <label class="col-form-label col-md-1 text-right">Weight</label>
                    <div class="col-md-1">
                      <input id="txtweight" ref="el_weight" type="number" class="form-control text-right" placeholder="Enter Weight" min="0" required
                             v-if="voucher" v-model="voucher.weight">
                    </div>

                    <label class="col-form-label col-md-1 text-right">Weight(Avg)</label>
                    <div class="col-md-1">
                      <input type="number" class="form-control text-right" tabindex="-1" placeholder="Avg Weight" min="0" v-model= "avgwgt" readonly>
                    </div>


                  </div>

                  <!--  Detail Table -->
                  <div class="form-group row">
                    <div class="table-responsive">

                      <table id="mytable" class="table table-no-bordered">
                        <thead style="background-color: lightgrey">
                        <tr>
                          <th style="width: 100px;">S.No</th>
                          <th>FabCode</th>
                          <th>#</th>
                          <th style="text-align: right;">Mtrs</th>
                          <th>Quality</th>
                          <th>Color</th>
                          <th style="width: 30px;">Action</th>
                        </tr>
                        </thead>
                        <tbody v-model="voucher" v-for="(fab,index) in voucher.fabrics">
                          <Detail :myindex="index" :parentvoucher="voucher" :myprocess="voucher.process.id" :myrow="fab" v-on:onrow_change="onrow_change" v-on:onRemoveRow="removeRow"></Detail>
                        </tbody>
                        <tfoot>
                        <tr>
                          <td>
                            <button id="btnAddRow" type="button"
                                    class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple"
                                    @click="addRow">
                              <i class="icon-plus3"></i>
                            </button>
                          </td>
                          <td></td>
                          <td></td>
                          <td class="text-right font-weight-semibold" style="padding-right: 10px;">{{ totalmts }}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <!-- / Detail Table -->


                </div>
              </div>
              <!-- /static mode -->

            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <div class="form-group form-group-material">
                <label class="control-label text-semibold">Narration</label>
                <textarea id="txtremarks" class="form-control" placeholder="Narration" v-if="voucher" v-model="voucher.remarks"> </textarea>
              </div>
            </div>


            <div class="col-md-6">

            </div>

            <div class="col-md-3 text-right">
              <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save
              </button>
              <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear">Clear</button>
            </div>
          </div>

        </div>

      </div>
    </FocusTrap>

  </div>
  </FocusTrap>
</template>

<script>

import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import Detail from './RollingDetailRow'

export default {
  name: 'RollingForm',
  store,
  components: {
    Detail,
  },
  data () {
    return {
      avgwgt:0,
      totalmts: 0,
      viewer: {},
      emps: [],
      colors: [],
      qualities: [],
      voucher: JSON.parse('{"id":0,"status":"Active","finyear":0,"cmp_id":1,"shift":{"id":0,"type":0,"name":""},"doc_date":"2000-01-01","roll_no":0,"emp":{"id":0,"status":0,"emp_code":"","name":""},"process":{"code":350,"name":"","nature":0},"quality":{"id":0,"name":""},"color":{"id":0,"status":0,"name":""},"qty":0,"weight":0,"avg":0,"fabrics":[],"remarks":""}')
    }
  },
  props: {
    myvoucher: {
      type: Object,
      default: () => JSON.parse('{"id":0,"status":"Active","finyear":0,"cmp_id":1,"shift":{"id":0,"type":0,"name":""},"doc_date":"2000-01-01","roll_no":0,"emp":{"id":0,"status":0,"emp_code":"","name":""},"process":{"code":350,"name":"","nature":0},"quality":{"id":0,"name":""},"color":{"id":0,"status":0,"name":""},"qty":0,"weight":0,"avg":0,"fabrics":[],"remarks":""}')
    }
  },
  created () {

  },
  beforeMount () {
    this.voucher = this.myvoucher;
  },
  mounted () {
    const self = this;

    try {

      self.loadColors();
      self.loadQualities();

      if (self.$data.voucher.doc_date == '2000-01-01' || self.$data.voucher.doc_date == '0001-01-01') {
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      }
      //self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');

      //self.$data.voucher =  JSON.parse('{"id":1769,"status":"Active","finyear":2023,"roll_no":766,"doc_date":"2024-02-02","cmp_id":1,"shift_id":2,"mac_id":1,"emp_id":1,"item_id":225,"quality_id":115,"color_id":107,"qty":70,"weight":7.225,"avg":9.689,"trn_id":1,"remarks":"","shift":{"id":2,"status":"Active","name":"Day"},"employee":{"id":1,"code":null,"status":"Active","name":null,"design_id":1,"extrusion":false,"stentering":false,"warping":false},"item":{"id":225,"status":"Active","type":"Roll","name":"Roll-PRANAV 10-D.BLUE","group_id":1,"unit_id":3,"brand_id":1,"quality_id":115,"color_id":107,"weight":0,"min_qty":0,"max_qty":0,"unit":{"id":3,"type":0,"name":"Mtr","status":"Active","digits":3},"quality":{"id":115,"type":0,"name":"PRANAV 10","status":"Active","weight":0},"color":{"id":107,"type":0,"name":"D.BLUE","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}},"quality":{"id":115,"type":0,"name":"PRANAV 10","status":"Active","weight":0},"color":{"id":107,"type":0,"name":"D.BLUE","status":"Active","weight":0}}');


      $('#cmbshift').focus();

    }catch (e) {
      alert(e);
    }

  },
  methods: {
    closeThis(){
      try {
        this.$emit('rolling_window_closed');
        if (this.voucher.id > 1) {
          // this.$modal.hide('voucher-window');
          this.voucher = JSON.parse('{"id":0,"status":"Active","finyear":0,"cmp_id":1,"shift":{"id":0,"type":0,"name":""},"doc_date":"2000-01-01","roll_no":0,"emp":{"id":0,"status":0,"emp_code":"","name":""},"process":{"code":350,"name":"","nature":0},"quality":{"id":0,"name":""},"color":{"id":0,"status":0,"name":""},"qty":0,"weight":0,"avg":0,"fabrics":[],"remarks":""}');
        } else {
          this.$router.push("/")
        }
      }catch (e) {
        alert(e.toString());
      }
    },
    indianFormat (val) {
      return parseFloat(val).toFixed(2)
    },
    addRow () {
      const self = this;
      try {

        let fabric =  JSON.parse('{"idx":0,"id":0,"status":"Active","finyear":1,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"item_id":1,"qua_id":1,"col_id":1,"fab_weight":0,"fab_waste_weight":0,"yarn_waste_weight":0,"defects_count":0,"trn_id":1,"needle_damages":0,"hole_damages":0,"other_damages":0,"stentering_damages":0,"remarks":"","mtrs":0,"weight":0,"avg_weight":0,"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":0,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}},"item":{"id":1,"status":"","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"used_qty":0,"used_weight":0}');
        self.$data.voucher.fabrics.push(fabric)
        setTimeout(function () {
          $('tbody>tr').last().find('td:eq(1) input').focus()
        }, 99)
      } catch (e) {
        alert(e)
      }
    },
    onrow_change (rowIndex, colIndex, data) {
      const self = this
      try {
        if (colIndex == 1){
          self.$data.voucher.fabrics[rowIndex].id = parseInt(data);
        }else if (colIndex == 3){
          self.$data.voucher.fabrics[rowIndex].used_qty = parseFloat(data);
        }


        self.$data.totalmts = 0.0
        self.$data.voucher.fabrics.forEach((fab) => {
          // if (fab.qty > 0) {
          self.$data.totalmts += parseFloat(fab.used_qty);
          //}
        });

        self.$data.avgwgt =  parseFloat(self.$data.totalmts) / parseFloat(self.$data.voucher.weight) ;
      } catch (e) {
        alert(e)
      }

    },
    removeRow (index) {
      const self = this

      if (index > -1) {
        this.$data.voucher.fabrics.splice(index, 1);
      }
    },
    clear () {
      const self = this

      // self.$data.voucher = JSON.parse('{"id":0,"status":0,"finyear":0,"shift":{"id":0,"type":0,"name":""},"date":"0001-01-01","roll_no":0,"emp":{"id":0,"status":0,"emp_cd":"","name":""},"process":{"id":0,"name":"","nature":0,"p_name":"","s_name":""},"quality":{"id":0,"name":"","p_name":""},"color":{"id":0,"status":0,"name":"","p_name":""},"qty":0,"weight":0,"avg":0,"fabrics":[],"remarks":""}')
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');

      self.$data.voucher.id = 0;
      self.$data.voucher.remarks = '';
      self.$data.voucher.weight = 0;
      self.$data.voucher.qty = 0;
      self.$data.voucher.avg = 0;
      self.$data.voucher.fabrics = [];
      // self.$data.avgwgt = 0;
      // self.$data.totalmts = 0;
      // $('#cmbshift').focus()
    },
    loadData(){
      const self = this;
      self.loadColors();
      self.loadQualities();
    },
    loadColors () {
      const self = this
      const requestOptions = {
        mode: 'cors',
        headers: userService.authHeader()
      }


      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.colors = []
      fetch(`${process.env.VUE_APP_ROOT_API}api/colorcharts`, requestOptions).then(userService.handleResponse).then(function (resp) {

        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.colors = resp.data;
            // alert(self.$data.colors.length);
          }
        } else {
          swal({ title: 'Oops', text: resp.msg, type: 'error', timer: 3000 })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error', timer: 3000 })
      }).finally( function (){
        $('#mycard').unblock();
      });
    },
    loadQualities () {
      const self = this;
      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.qualities = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/qualities`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if(Array.isArray(resp.data)){
            self.$data.qualities =  resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $(self.$data.mytable).unblock();
      });
    },
    saveVoucher () {
      const self = this

      self.$data.voucher.finyear = self.$store.state.user.finyear;
      self.$data.voucher.status = "Active";
      self.$data.voucher.cmp_id = self.$store.state.user.company.id;

      let myvoucher = JSON.parse(JSON.stringify(self.$data.voucher))

      myvoucher.doc_date = moment(self.$data.voucher.date).format('YYYY-MM-DD');
      myvoucher.finyear = store.state.user.finyear
      myvoucher.shift_id = parseInt(myvoucher.shift_id)
      myvoucher.process_id = 350;//parseInt(myvoucher.process.id)
      myvoucher.qty = parseFloat(self.$data.totalmts)
      myvoucher.weight = parseFloat(myvoucher.weight)
      myvoucher.avg = parseFloat(self.$data.avgwgt)

      myvoucher.quality_id = parseInt(myvoucher.quality_id);
      myvoucher.color_id = parseInt(myvoucher.color_id);

      let arr = [];

      myvoucher.fabrics.forEach((fab)=>{
        fab.id  = parseInt(fab.id);
        arr.push(fab.id);
      });

      if (myvoucher.shift_id < 1) {
        alert('Invalid Shift')
        return
      } else if (myvoucher.process_id < 1) {
        alert('Invalid Process')
        return
      } else if (myvoucher.quality_id < 1) {
        alert('Invalid Quality')
        return
      } else if (myvoucher.color_id < 1) {
        alert('Invalid Color')
        return
      } else if (myvoucher.qty <= 0) {
        alert('Invalid Roll Mtr')
        return
      } else if (self.$data.totalmts <= 1) {
        alert('Invalid Roll Meter')
        return
      } else if (new Set(arr).size !== arr.length) {
        alert('Duplicate Fabric Code')
        return
      }

      // console.log(JSON.stringify(myvoucher));


      const requestOptions = {
        method: (self.$data.voucher.id == 0 ? 'POST' : 'PUT'),
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(myvoucher)
      }


      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });


      fetch(`${process.env.VUE_APP_ROOT_API}api/roll`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          //self.$data.voucher = resp.data
          self.$data.voucher.fabrics = [];
          self.$data.voucher.id = 0;
          self.$data.voucher.remarks = '';
          self.$data.voucher.weight = 0;
          self.$data.voucher.qty = 0;
          self.$data.voucher.avgwgt = 0;
          self.$data.totalmts = 0;

          swal({ type: 'success', text: resp.msg, onClose: () => { $('#txtweight').focus()}, timer:1500 });
          self.$emit('rolling_saved', resp.data);
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error', onClose: () => { $('#cmbshift').focus()}, timer:3000 })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error', onClose: () => { $('#cmbshift').focus()}, timer:3000 })
      }).finally(function () {
        $('#mycard').unblock();
      })
    },
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
