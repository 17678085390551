<template>
  <tr style="padding: 0px;">
    <td style="text-align: center;padding: 0px;"> {{ row.idx + 1}} </td>

    <td  style="padding: 0px;width: 60px;">
      <input ref="el_fabcode" type="number" class="form-control" v-model="row.id" @keyup.enter="loadFabric(row.id)" />
    </td>
    <td style="padding: 0px;">
      <button class="btn btn-outline-success" @click="loadFabric(row.id)" tabindex="-1" >Find</button>
    </td>

    <td style="padding: 0px;text-align: right;width: 100px;">
      <input ref="el_qty" type="number" class="form-control text-right" v-if="row" v-model="row.used_qty" @change="showmsg(3)" />
    </td>

    <td style="padding: 0px;">
      {{row.quality.name}}
    </td>
    <td style="padding: 0px;">
      {{row.color.name}}
    </td>


    <td style="padding: 0px;text-align: right">
      <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
    </td>

  </tr>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'

  export default {
    name: 'RollingDetailRow',
    props: {
      myrow: {
        type: Object,
        default: () => JSON.parse('{"idx":0,"id":0,"status":"Active","finyear":1,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"item_id":1,"qua_id":1,"col_id":1,"fab_weight":0,"fab_waste_weight":0,"yarn_waste_weight":0,"defects_count":0,"trn_id":1,"needle_damages":0,"hole_damages":0,"other_damages":0,"stentering_damages":0,"remarks":"","mtrs":0,"weight":0,"avg_weight":0,"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":0,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}},"item":{"id":1,"status":"","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"used_qty":0,"used_weight":0}')
      },
      parentvoucher:{
        type:Object,
        default:() => JSON.parse('{"idx":0,"id":0,"status":"Active","finyear":0,"cmp_id":1,"shift":{"id":0,"type":0,"name":""},"doc_date":"2000-01-01","roll_no":0,"emp":{"id":0,"status":0,"emp_code":"","name":""},"process":{"code":350,"name":"","nature":0},"quality":{"id":0,"name":""},"color":{"id":0,"status":0,"name":""},"qty":0,"weight":0,"avg":0,"fabrics":[],"remarks":""}'),
      },
      myindex: {
        type: Number,
        default: () => 0
      },
      myprocess: {
        type: Number,
        default: () => 0
      }
    },
    beforeMount () {
      this.row = this.myrow;
      this.index = this.myindex;
      this.row.idx = this.myindex;
    },
    data () {
      return {
        index: 0,
        row : JSON.parse('{"idx":0,"id":0,"status":"Active","finyear":1,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"item_id":1,"qua_id":1,"col_id":1,"fab_weight":0,"fab_waste_weight":0,"yarn_waste_weight":0,"defects_count":0,"trn_id":1,"needle_damages":0,"hole_damages":0,"other_damages":0,"stentering_damages":0,"remarks":"","mtrs":0,"weight":0,"avg_weight":0,"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":0,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}},"item":{"id":1,"status":"","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"used_qty":0,"used_weight":0}')
      }
    },
    filters:{
      weightFormat (val){
        return val.toFixed(3);
      }
    },
    methods:{
      removeRow(){
        this.$emit('onRemoveRow', Math.abs(this.row.idx) );
      },
      loadFabric(id){
        if(id < 1) {
          return;
        }

        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.row.idx = self.myindex ;

        //api/workorder/receipt/code/

        let url = `api/workorder/receipt/code/${id}/`

        if(self.$props.myprocess == 350){ //Knitting
          url = `${process.env.VUE_APP_ROOT_API}api/knitting/receipt/${id}`;
        }else if(self.$props.myprocess == 305){ //weaving
          url = `${process.env.VUE_APP_ROOT_API}api/workorder/receipt/${id}`;
        }

        url = `${process.env.VUE_APP_ROOT_API}api/knitting/receipt/${id}`;

        fetch(url,requestOptions).then(userService.handleResponse).then(function (resp) {

          if(resp.ok) {

            resp.data.used_qty= 0;
            resp.data.used_weight = 0;

            if(!_.isNull(resp.data)) {
              if(self.validateQualityColor(resp.data)){
                self.$data.row = resp.data;
                self.$data.row.idx = self.myindex;
                self.showmsg(1);
                self.$refs.el_qty.focus();
              }

            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error", timer:3000} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error", timer:3000} );
        }).finally(function () {
          // $(self.$data.mycard).unblock();
        });
      },
      showmsg(colIndex) {
        const self = this;


        switch (colIndex) {
          case 1:
            // this.row.fabric.id
            this.$emit('onrow_change', self.index, colIndex, self.row.id);
            break;
          case 3:
            this.$emit('onrow_change', self.index, colIndex, parseFloat(self.row.used_qty));
            break;
        }
      },
      validateQualityColor(fab){

        const self = this;
        let result = false;
        try{

          // console.log(JSON.stringify(self.$props.parentvoucher));


          if(self.$props.parentvoucher.quality_id !== fab.quality.id){
            alert("Invalid Quality");
            self.$refs.el_fabcode.focus();
          }else if(self.$props.parentvoucher.color_id !== fab.color.id){
            alert("Invalid Color");
            self.$refs.el_fabcode.focus();
          }else if(self.$props.parentvoucher.quality_id === fab.quality.id && self.$props.parentvoucher.color_id === fab.color.id){
            result = true;
          }

        }catch (e) {
          alert(e);
        }

        return result;
      },
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
